import Parse from 'parse';

export const FETCH_PROJECTTYPES = "FETCH_PROJECTTYPES";

export function fetch(){
    return {
        type: FETCH_PROJECTTYPES,
        payload: new Parse.Query("ProjectType")
            .notEqualTo("deleted", true)
            .ascending("name")
            .find()
    };
}