import React from 'react'
import {Button, Glyphicon, Modal} from 'react-bootstrap';
import {connect} from "react-redux";
import RegistrationMapOverlayBuilder from "./RegistrationMapOverlayBuilder";
import * as MapAction from "../actions/maps";
import ProjectCoordinatesField from "./ProjectCoordinatesField";
import AddMapPoint from "./AddMapPoint";
import ProjectMapZoomField from "./ProjectMapZoomField";
import MapPolyEditor from "./MapPolyEditor";


class MapOverlayBuilder extends RegistrationMapOverlayBuilder {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            editingMapPoint: null,
        }
        // this.state = Object.assign({}, this.state, {selected});
        this.props.fetchPointTypes();
    }

    render() {
        const {windparks} = this.props;
        let coordinates = this.props.project.get("coordinates");
        return (
            <div>
                <ProjectCoordinatesField project={this.props.project}/>
                <ProjectMapZoomField project={this.props.project}/>
                <br className="clearfix"/>
                <br className="clearfix"/>

                {coordinates && (
                    <div>
                        {super.render()}
                        <br/><br/>

                        <MapPolyEditor {...this.state} />

                        <table className="table table-condensed">
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Asset Group</th>
                                <th>Coordinates</th>
                                <th>Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.values(this.state.points).map((point) => (
                                <tr key={point.id}>
                                    <td>{point.type.name}</td>
                                    <td>{point.description}</td>
                                    <td>{point.windparkId && windparks[point.windparkId] && windparks[point.windparkId].get("name")}</td>
                                    <td>{point.coordinates.lat || 0}</td>
                                    <td>{point.coordinates.lng || 0}</td>
                                    {/*<td><input className="input-sm" type="text" value={point.description || ''}*/}
                                    {/*onChange={this.formChanged.bind(this, point.id, "description")} /></td>*/}
                                    {/*<td>*/}
                                    {/*<input className="input-sm" type="text" value={point.coordinates.lat || 0}*/}
                                    {/*onChange={this.formChanged.bind(this, point.id, "lat")}/>*/}
                                    {/*<input className="input-sm" type="text" value={point.coordinates.lng || 0}*/}
                                    {/*onChange={this.formChanged.bind(this, point.id, "lng")}/>*/}
                                    {/*</td>*/}
                                    <td>
                                        <span>
                                            <Button bsStyle="warning"
                                                    onClick={() => this.setState({editingMapPoint: point})}>
                                                <Glyphicon
                                                    glyph="edit"/></Button>{' '}
                                            {point.removed ? (
                                                <span>removed</span>
                                            ) : (
                                                <Button bsStyle="danger"
                                                        onClick={() => {
                                                            if (window.confirm("Are you sure you want to remove this point?"))
                                                                this.props.removePointById(point.id)
                                                        }
                                                        }><Glyphicon
                                                    glyph="remove"/></Button>
                                            )}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {/*<Button bsStyle="primary" onClick={this.save.bind(this)}>Save</Button>&nbsp;*/}
                        <AddMapPoint project={this.props.project}/>

                        {this.state.editingMapPoint && <Modal show={!!this.state.editingMapPoint} onHide={() => {
                            this.setState({editingMapPoint: null})
                        }}>
                            <div style={{padding: 20}}>
                                <AddMapPoint project={this.props.project} onSave={() => {this.setState({editingMapPoint: null})}}
                                             editPoint={this.state.editingMapPoint}/>
                            </div>
                        </Modal>}
                        {/*<span>&nbsp;{this.state.message}</span>*/}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(state => ({
    windparks: state.projects.settings.windparks || {},
}), dispatch => ({
    fetchPointTypes: () => dispatch(MapAction.fetchPointTypes()),
    removePointById: (point) => dispatch(MapAction.removePointById(point)),
}))(MapOverlayBuilder);