import React from 'react';
import Parse from 'parse';
import {NavLink} from 'react-router-dom'
import MemberRow from '../memberRow';
import AddMember from '../addMember';
import MapOverlayBuilder from '../components/MapOverlayBuilder'
import {connect} from "react-redux";
import * as ProjectAction from "../actions/projects";
import * as UserAction from "../actions/users";
import * as TypeAction from "../actions/registrationTypes";
import * as WorkpackagesAction from "../actions/workpackages";
import ProjectWorkPackages from "../components/ProjectWorkPackages";
import * as Roles from "../actions/roles";
import {Button, ControlLabel, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import ProjectRoles from "../components/ProjectRoles";
import * as ProjectActions from "../actions/projects";
import ProjectWindparks from "../components/ProjectWindparks";
import ProjectSettings2 from "../components/ProjectSettings2";

class EditProject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: props.projectId,
            members: [],
            memberIds: [],
            admin: false,
            // users: [],
        };
        props.fetchTypes();
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {

        let projectId = this.props.projectId;
        this.props.setActiveProjectById(projectId);
        this.props.fetchMembersByProjectById(projectId);
        this.props.fetchWorkpackages(projectId);
        this.props.fetchRoles(projectId);
        this.props.fetchSettings();
        // this.props.fetchUsers();

        let _this = this;
        let currentUser = Parse.User.current();
        if (currentUser !== null) {
            currentUser.fetch().then(user => {
                _this.setState({admin: user.get("admin")})
            })
        }

    }

    getUserManagementUI() {
        const {project, members} = this.props;

        //j.bijker: f2F7sHmtvB
        let filteredMembers = (window.location.hash) ? members : members.filter(m =>
            m.get('user').id !== "f2F7sHmtvB" && m.get('user').id !== "xQ6wP6lZL5"
        );
        filteredMembers.sort(function (a, b) {
            let nameA = a.get('user').get("userInfo").get("name").toUpperCase(); // ignore upper and lowercase
            let nameB = b.get('user').get("userInfo").get("name").toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

        if (!project) return null;
        let userManagement = filteredMembers.length > 0 ? (
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>User</th>
                    {project.isAdmin && (
                        <th style={{width: 140}}>Role</th>
                    )}
                    <th style={{width: 140,color:"#ccc"}}>Type</th>
                    {project.isAdmin && (
                        <th style={{width: 200,color:"#ccc"}}>Work Packages</th>
                    )}
                    {project.isAdmin && (
                        <th style={{width: 140}}>Notifications</th>
                    )}
                    {project.isAdmin && (
                        <th style={{width: 80}}>Actions</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {filteredMembers.map(member => (
                    <MemberRow key={member.id} member={member} user={member.get('user')}
                               isHSEManager={project.isHSEManager} isAdmin={project.isAdmin}/>
                ))
                }
                </tbody>
            </table>
        ) : (
            <div className="card-body">
                <em>No members added yet.<br/><br/></em>
            </div>
        );

        userManagement = <div>
            {userManagement}
            {project.isAdmin && (
                <AddMember currentMembers={this.props.members} project={project}/>
            )}
        </div>;

        return userManagement;
    }

    getMapOverlayUI() {
        const {project, map} = this.props;
        return project && map && !map.fetching && (
            <div className="card-body"><MapOverlayBuilder project={project} map={map} editMode="true"/></div>);
    }

    render() {
        const {project} = this.props;
        let currentPage = this.state.admin ? "settings" : "user-management";
        if (this.props.location.pathname.indexOf("map-overlay") !== -1) currentPage = "map-overlay";
        if (this.props.location.pathname.indexOf("user-management") !== -1) currentPage = "user-management";
        if (this.props.location.pathname.indexOf("settings") !== -1) currentPage = "settings";
        if (this.props.location.pathname.indexOf("workpackages") !== -1) currentPage = "workpackages";
        if (this.props.location.pathname.indexOf("roles") !== -1) currentPage = "roles";
        if (this.props.location.pathname.indexOf("windparks") !== -1) currentPage = "windparks";

        return (
            <div className="card">
                <div className="card-heading">
                    {project && (
                        <h2>
                            Edit project&nbsp;
                            <small>{project.get("name")}</small>
                        </h2>
                    )}
                </div>
                <ul className="nav nav-tabs" role="tablist">
                    {this.state.admin && <li className={currentPage === "settings" ? "active" : ""}><NavLink
                        to={"/edit-project/" + this.props.projectId + "/settings"}>Project Settings</NavLink>
                    </li>}
                    <li className={currentPage === "user-management" ? "active" : ""}><NavLink
                        to={"/edit-project/" + this.props.projectId + "/user-management"}>User Management</NavLink>
                    </li>
                    <li className={currentPage === "roles" ? "active" : ""}><NavLink
                        to={"/edit-project/" + this.props.projectId + "/roles"}>Roles</NavLink>
                    </li>
                    <li className={currentPage === "workpackages" ? "active" : ""}><NavLink
                        to={"/edit-project/" + this.props.projectId + "/workpackages"}>Work Packages</NavLink>
                    </li>
                    <li className={currentPage === "windparks" ? "active" : ""}><NavLink
                        to={"/edit-project/" + this.props.projectId + "/windparks"}>Asset Groups</NavLink>
                    </li>
                    {this.state.admin && (
                        <li className={currentPage === "map-overlay" ? "active" : ""}><NavLink
                            to={"/edit-project/" + this.props.projectId + "/map-overlay"}>Map Settings</NavLink></li>
                    )}
                </ul>
                {currentPage === "roles" && <ProjectRoles project={project}/>}
                {this.state.admin && currentPage === "map-overlay" && this.getMapOverlayUI()}
                {currentPage === "user-management" && this.getUserManagementUI()}
                {currentPage === "workpackages" && <ProjectWorkPackages project={project}/>}
                {currentPage === "windparks" && <ProjectWindparks project={project}/>}
                {this.state.admin && currentPage === "settings" && <ProjectSettings2 project={project}/>}
            </div>
        );
    }
}

export default connect(state => ({
    map: state.maps.items[state.projects.active],
    project: state.projects.items[state.projects.active],
    members: (state.users.memberships[state.projects.active] && state.users.memberships[state.projects.active].items) || [],
}), dispatch => ({
    fetchSettings: () => dispatch(ProjectActions.fetchProjectSettings()),
    fetchTypes: () => dispatch(TypeAction.fetch()),
    fetchWorkpackages: projectId => dispatch(WorkpackagesAction.fetch(projectId)),
    fetchRoles: projectId => dispatch(Roles.fetch(projectId)),
    setActiveProjectById: project => dispatch(ProjectAction.setActiveProjectById(project)),
    fetchMembersByProjectById: project => dispatch(UserAction.fetchMembersByProjectById(project)),
    // fetchUsers: () => dispatch(UserAction.fetchUsers()),
}))(EditProject);