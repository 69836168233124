import React from 'react';
import Parse from 'parse';
import {connect} from "react-redux";
import {Button, Col, FormControl, FormGroup} from 'react-bootstrap';
import * as MapAction from "../actions/maps";
// import turbines from "../resources/turbines.js";

class AddMapPoint extends React.Component {

    constructor(props) {
        super(props);
        if (props.editPoint) {
            const p = props.editPoint;
            console.log(p);
            this.state = {
                adding: true,
                editing: true,
                id: p.id,
                type: p.type.id,
                name: p.description,
                lat: p.coordinates.lat,
                lng: p.coordinates.lng,
                windpark: p.windparkId,
                editingPoint: p.point,
            }
        } else {
            this.state = {
                adding: false,
                lat: "",
                lng: "",
                name: "",
                type: null,
                windpark: null,
            }
        }
    }

    handleChange(event) {
        let value = event.target.name === "name" ? event.target.value : event.target.value.replace(/[^\d.]/g, "");
        this.setState({[event.target.name]: value});
    }

    changeSelectedType(event) {
        this.setState({type: event.target.value})
    }

    changeSelectedWindpark(event) {
        this.setState({windpark: event.target.value})
    }

    add() {
        this.setState({adding: true});
        // this.parseCSV();
    }

    save() {

        let project = this.props.project;
        let coordinates = new Parse.GeoPoint({
            latitude: parseFloat(this.state.lat),
            longitude: parseFloat(this.state.lng)
        });

        let Point = Parse.Object.extend("MapOverlayPoint");
        let point = this.state.editingPoint || new Point();
        point.set("coordinates", coordinates);
        point.set("project", project);
        point.set("active", true);
        point.set("description", this.state.name);
        point.set("windpark", this.props.windparks[this.state.windpark]);
        point.set("type", this.props.pointTypes[this.state.type]);

        this.props.savePoint(point, !this.state.editing);
        if (this.props.onSave) this.props.onSave();

        this.setState({
            lat: "",
            lng: "",
            name: "",
            adding: false,
        })

    }

    // parseCSV() {
    //
    //     console.log("PARSING CSV");
    //     let lines = turbines.split("\n");
    //     for (let line of lines){
    //         let turbine = line.split(";");
    //         console.log(turbine);
    //         this.saveNewTurbine(turbine[0], turbine[1].replace(",", "."), turbine[2].replace(",", "."));
    //     }
    //
    // }

    // saveNewTurbine(name, lat, lng){
    //     let project = this.props.project;
    //     let coordinates = new Parse.GeoPoint({
    //         latitude: parseFloat(lat),
    //         longitude: parseFloat(lng)
    //     });
    //
    //     let Point = Parse.Object.extend("MapOverlayPoint");
    //     let point = new Point();
    //     point.set("coordinates", coordinates);
    //     point.set("project", project);
    //     point.set("active", true);
    //     point.set("description", name);
    //     point.set("type", new Parse.Object('MapOverlayPointType', {id: "5uVGWix6zM"})); //5uVGWix6zM
    //
    //     this.props.savePoint(point);
    //
    //     this.setState({
    //         lat: "",
    //         lng: "",
    //         name: "",
    //         adding: false,
    //     })
    // }



    render() {
        return (
            this.state.adding ? (
                <div className="clearfix">
                    <br/>
                    <FormGroup>
                        {!this.state.editing && <Col xs={1}>
                            Add point:
                        </Col>}
                        <Col xs={4}>
                            <FormControl componentClass="select" placeholder="select" name="newItem"
                                         onChange={this.changeSelectedType.bind(this)}
                                         value={this.state.type}>
                                <option>Select type</option>
                                {Object.values(this.props.pointTypes).map(type => (
                                    <option key={type.id} value={type.id}>
                                        {type.get("name")}
                                    </option>
                                ))}
                            </FormControl>
                            <FormControl componentClass="select" placeholder="select" name="newItem"
                                         onChange={this.changeSelectedWindpark.bind(this)}
                                         value={this.state.windpark}>
                                <option>Select Asset Group (optional)</option>
                                {Object.values(this.props.windparks || {}).filter(w =>
                                    w.get("project").id === this.props.project.id && !w.get("deleted")
                                ).map(type => (
                                    <option key={type.id} value={type.id}>{type.get("name")}</option>
                                ))}
                            </FormControl>
                        </Col>
                        <Col xs={7}>
                            <input className="input-sm" type="text" name="name" value={this.state.name}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder="Description"/>
                            <input className="input-sm" type="text" name="lat" value={this.state.lat}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder="Latitude"/>
                            <input className="input-sm" type="text" name="lng" value={this.state.lng}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder="Longitude"/>
                        </Col>
                        <Button className="pull-right" bsStyle="default" onClick={this.save.bind(this)}>Save</Button>
                    </FormGroup>
                </div>
            ) : (
                <Button bsStyle="default" onClick={this.add.bind(this)}>Add</Button>
            )
        );
    }
}

export default connect(state => ({
    pointTypes: state.maps.pointTypes,
    windparks: state.projects.settings.windparks || {},
}), dispatch => ({
    savePoint: (project, newPoint) => dispatch(MapAction.savePoint(project, newPoint)),
}))(AddMapPoint);