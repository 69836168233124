import * as Action from '../actions/projectTypes'
import {PENDING, FULFILLED} from '../actions/constants';
import update from 'immutability-helper';

export function projectTypes(state = {
    items: {},
    active: null,
}, action) {
    switch (action.type) {
        case Action.FETCH_PROJECTTYPES + PENDING:
            return update(state, {
                items: {
                    $merge: {
                        fetching: true,
                    }
                }
            });
        case Action.FETCH_PROJECTTYPES + FULFILLED:
            return update(state, {
                items: {
                    fetching: {$set: false},
                    items: {$set: action.payload}
                }
            });


        default:
            return state;
    }
}
