import React from 'react';
import {connect} from "react-redux";
import * as Actions from "../actions/projectTypes";
import {Alert, Button, ControlLabel, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import spinnerImage from "../resources/images/spinner.gif";
import * as ProjectAction from "../actions/projects";

class ProjectSettings2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: null,
        };
    }

    close() {
        this.setState({
            editing: null,
        });
    }

    componentWillMount() {
        this.props.fetchProjectTypes();
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    setType(type) {
        let {project} = this.props;
        project.set("type", type);
        this.props.saveProject(project);

        //     .save().then((e) => {
        //     console.log("Saved!");
        //     console.log(e);
        // });
    }

    render() {


        let {projectTypes, project} = this.props;
        // let {modalOpen, newWindparkName, newWindparkDescription, editing} = this.state;

        return <div style={{padding: 16}}>

            {!projectTypes || projectTypes.fetching &&
                <img src={spinnerImage} alt="Loading..." className="spinner-centered"/>}

            {project && project.isAdmin && projectTypes && projectTypes.items && <>

                <h3>Project Type</h3>
                <div style={{display: "flex", columnGap: 8}}>
                    {projectTypes.items.map((type, index) => {
                        const currentTypeId = project.get("type") && project.get("type").id;
                        const active = currentTypeId === type.id || (!currentTypeId && type.id === "4bMFXRnzkW");
                        return <div key={index}
                                    style={{cursor: "pointer"}}
                                    onClick={() => this.setType(type)}
                                    className={"panel " + (active ? "panel-info" : "")}>
                            <div className="panel-heading" style={{padding: 0, paddingLeft: 8}}>
                                <h3 className="panel-title" style={{color: active ? "white" : "gray"}}>{type.get("name")}</h3>
                            </div>
                            <div className="panel-body" style={{color: active ? "black" : "gray"}}>
                                <img src={"/resources/images/" + type.get("assetGroupIcon")} style={{width: 16, height: 16}}/>
                                {" "} <strong>Asset group name:</strong><br />
                                <strong>EN:</strong> {type.get("assetGroupName")}<br />
                                <strong>NL:</strong> {type.get("assetGroupNameNL")}
                                <br />
                                <br />
                                <img src={"/resources/images/" + type.get("assetIcon")} style={{width: 16, height: 16}}/>
                                {" "} <strong>Asset name:</strong><br />
                                <strong>EN:</strong> {type.get("assetName")}<br />
                                <strong>NL:</strong> {type.get("assetNameNL")}
                            </div>
                        </div>
                    })}
                </div>
            </>}

        </div>
    }

}


export default connect(state => ({
    projectTypes: state.projectTypes.items,
}), dispatch => ({
    fetchProjectTypes: () => dispatch(Actions.fetch()),
    saveProject: (project) => dispatch(ProjectAction.saveProject(project, true)),
    // addNewWindpark: (projectId, name, description) => dispatch(WPActions.newWindpark(projectId, name, description)),
    // editWindpark: (projectId, wpId, name) => dispatch(WPActions.editWindpark(projectId, wpId, name)),
}))(ProjectSettings2);

